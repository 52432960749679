import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { update } from 'lodash';
import localStorage from 'redux-persist/es/storage';

const loginURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_AUTH_API_URL;
const regURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_API_REG_URL;
const resetURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_PASSWORD_RESET;
const updateURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_PASSWORD_UPDATE;
const verifyKeyURL = process.env.REACT_APP_API_ENDPOINT+process.env.REACT_APP_CHECK_RESET_KEY;

const initialState = {
    error: null,
    isAuth: false
};

export const registerUser = createAsyncThunk(
    'auth/registerUser',
    async (details, { rejectWithValue }) => {
        const { email, whatsAppNumber, password, passwordConfirmation, firstName, lastName, accountType } = details

        if(password !== passwordConfirmation){
            return rejectWithValue('Passowrds do not match');
        }

        try{
            const response = await axios.post(regURL, 
                {
                    email,
                    password,
                    whatsAppNumber,
                    firstName,
                    lastName,
                    accountType
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message);
        }
    }
);


// Async thunk for logging in
export const login = createAsyncThunk(
    'auth/login',
    async (credentials, { rejectWithValue }) => {
        const  { username, password } = credentials;
        try{
            const response = await axios.post(loginURL, 
                {
                    username,
                    password
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message || 'Login failed');
        }
    }
);


// Thunk for logging in with email
export const loginWithEmail = createAsyncThunk(
    'auth/loginWithEmail',
    async (email, { rejectWithValue }) => {
        try{
            const response = await axios.post(loginURL, 
                {
                    email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message || 'Login failed');
            // TODO: If the user does not exists then redirect the user to registration with info from Google
        }
    }
);

// Thunk for verifying reset key
export const verifyResetKey = createAsyncThunk(
    'auth/verifyResetKey',
    async (details, { rejectWithValue }) => {
        const { login, key } = details;
        
        try{
            const response = await axios.post(verifyKeyURL, 
                {
                    login,
                    key
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message); // 'Key verification failed');
        }
    }
)

// Thunk for resetting password
export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async (email, { rejectWithValue }) => {
        try{
            const response = await axios.post(resetURL, 
                {
                    email,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message || 'Password reset failed');
        }
    }
);

// Thunk for updating password
export const updatePassword = createAsyncThunk(
    'auth/updatePassword',
    async (details, { rejectWithValue }) => {
        const { login, password, key } = details;
        try{
            const response = await axios.post(updateURL, 
                {
                    login,
                    password,
                    key
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );
            return response.data;
        }catch(err){
            return rejectWithValue(err.response?.data?.message);
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout(state) {
            state.status = 'idle';
            state.error = null;
            state.isAuth = false;
            localStorage.removeItem('user');
        },
   
  },
  extraReducers: (builder) => {
    builder
        .addMatcher(
            (action) => action.type.endsWith('/pending'), 
            (state) => {
                state.status = 'loading';
                state.error = null; // Clear any previous errors
            }
        )
        // Handle fulfilled states for both login and loginWithEmail
        .addMatcher(
            (action) => action.type.endsWith('/fulfilled'),
            (state, action) => {
                const userData = action.payload;
                if(action.type === 'auth/verifyResetKey/fulfilled' || action.type === 'auth/resetPassword/fulfilled' || action.type === 'auth/updatePassword/fulfilled'){
                    state.status = 'success';
                    state.error = null;
                }
                if(userData?.data?.token){
                    state.status = 'success';
                    state.error = null;
                    state.isAuth = true;
                    localStorage.setItem('user', JSON.stringify({
                        token: userData?.data?.token,
                        userId: userData?.data?.id,
                        displayName: userData?.data?.displayName,
                        roles: userData?.data?.roles,
                        user: userData?.data?.user,
                    }));
                }
            }
        )

        // Handle rejected states for both login and loginWithEmail
        .addMatcher(
            (action) => action.type.endsWith('/rejected'),
            (state, action) => {

                if(action.type === 'auth/verifyResetKey/rejected'){
                    state.status = 'rejected';
                    state.error = action.payload;
                }
                if(action.type === 'auth/updatePassword/rejected'){
                    state.status = 'rejected';
                    state.error = action.payload;
                }
                if(action.type === 'auth/registerUser/rejected'){
                    state.status = 'rejected';
                    state.error = action.payload;
                }
                
                if(action.type === 'auth/login/rejected'){
                    state.status = 'rejected';
                    state.error = action.payload;
                    state.isAuth = false;
                }
            }
        )
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;