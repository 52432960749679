import { GoogleLogin } from '@react-oauth/google';
import { SvgIcon } from '../svg-icon/svg-icon';
import {
  BottomSection,
  Button,
  Container,
  Line,
  Text,
  TextWithLines,
  Buttons
} from './google-facebook-auth-styled';
import { jwtDecode } from 'jwt-decode';
import { useDispatch, useSelector } from 'react-redux';
import { loginWithEmail } from '../../redux/slices/auth/authSlice';
import FacebookLogin from 'rc-facebook-login';

export const GoogleFacebookAuth = ({ children, description }) => {

  const dispatch = useDispatch();

  const handleGoogleLogin = (response) => {
    const email = jwtDecode(response.credential).email;
    dispatch(loginWithEmail(email));
  }
  return (
    <Container>
      <TextWithLines>
        <Line />
        <Text> {description} </Text>
        <Line />
      </TextWithLines>
      <Buttons>
        <GoogleLogin
          ux_mode='popup'
          onSuccess={handleGoogleLogin}
          onError={() => {
            console.log('Login Failed');
          }}
        />

        
        {/* TODO: Add Facebook OAuth Login  */}

        {/*
          <Button>
            <SvgIcon name={'google'} />
            Google
          </Button> 
        */}

        {/* 
          <FacebookLogin
            appId={'FB_APP_ID'}
            fields='name,email,picture'
            // callback={}
            className='facebook-login-button'
            // icon={<SvgIcon name={'facebook'} />}

            render={({ disabled, onClick }) => (
              <Button>
                <SvgIcon name={'facebook'} />
                Facebook
              </Button>
            )}
          />
        */}
      </Buttons>
      <BottomSection>{children}</BottomSection>
    </Container>
  );
};
