import Flag from 'react-world-flags';
import { GlobalInput } from '../global-input/global-input';
import { Container } from './global-input-select-styled';
import Select from 'react-select';
import { useState } from 'react';

const FlagIcon = ({ code }) => {
  return <Flag code={code} style={{ width: '24px', height: '16px' }} />;
};

const phonePrefixOptions = [
    { value: '+263', label: <><FlagIcon code="ZW" /> +263</> }, // Zimbabwe
    { value: '+93', label: <><FlagIcon code="AF" /> +93</> }, // Afghanistan
    { value: '+355', label: <><FlagIcon code="AL" /> +355</> }, // Albania
    { value: '+213', label: <><FlagIcon code="DZ" /> +213</> }, // Algeria
    { value: '+376', label: <><FlagIcon code="AD" /> +376</> }, // Andorra
    { value: '+244', label: <><FlagIcon code="AO" /> +244</> }, // Angola
    { value: '+54', label: <><FlagIcon code="AR" /> +54</> }, // Argentina
    { value: '+374', label: <><FlagIcon code="AM" /> +374</> }, // Armenia
    { value: '+61', label: <><FlagIcon code="AU" /> +61</> }, // Australia
    { value: '+43', label: <><FlagIcon code="AT" /> +43</> }, // Austria
    { value: '+994', label: <><FlagIcon code="AZ" /> +994</> }, // Azerbaijan
    { value: '+1-242', label: <><FlagIcon code="BS" /> +1-242</> }, // Bahamas
    { value: '+973', label: <><FlagIcon code="BH" /> +973</> }, // Bahrain
    { value: '+880', label: <><FlagIcon code="BD" /> +880</> }, // Bangladesh
    { value: '+1-246', label: <><FlagIcon code="BB" /> +1-246</> }, // Barbados
    { value: '+375', label: <><FlagIcon code="BY" /> +375</> }, // Belarus
    { value: '+32', label: <><FlagIcon code="BE" /> +32</> }, // Belgium
    { value: '+501', label: <><FlagIcon code="BZ" /> +501</> }, // Belize
    { value: '+229', label: <><FlagIcon code="BJ" /> +229</> }, // Benin
    { value: '+975', label: <><FlagIcon code="BT" /> +975</> }, // Bhutan
    { value: '+591', label: <><FlagIcon code="BO" /> +591</> }, // Bolivia
    { value: '+387', label: <><FlagIcon code="BA" /> +387</> }, // Bosnia and Herzegovina
    { value: '+267', label: <><FlagIcon code="BW" /> +267</> }, // Botswana
    { value: '+55', label: <><FlagIcon code="BR" /> +55</> }, // Brazil
    { value: '+673', label: <><FlagIcon code="BN" /> +673</> }, // Brunei
    { value: '+359', label: <><FlagIcon code="BG" /> +359</> }, // Bulgaria
    { value: '+226', label: <><FlagIcon code="BF" /> +226</> }, // Burkina Faso
    { value: '+257', label: <><FlagIcon code="BI" /> +257</> }, // Burundi
    { value: '+855', label: <><FlagIcon code="KH" /> +855</> }, // Cambodia
    { value: '+237', label: <><FlagIcon code="CM" /> +237</> }, // Cameroon
    { value: '+1', label: <><FlagIcon code="CA" /> +1</> }, // Canada
    { value: '+56', label: <><FlagIcon code="CL" /> +56</> }, // Chile
    { value: '+86', label: <><FlagIcon code="CN" /> +86</> }, // China
    { value: '+57', label: <><FlagIcon code="CO" /> +57</> }, // Colombia
    { value: '+506', label: <><FlagIcon code="CR" /> +506</> }, // Costa Rica
    { value: '+385', label: <><FlagIcon code="HR" /> +385</> }, // Croatia
    { value: '+53', label: <><FlagIcon code="CU" /> +53</> }, // Cuba
    { value: '+357', label: <><FlagIcon code="CY" /> +357</> }, // Cyprus
    { value: '+420', label: <><FlagIcon code="CZ" /> +420</> }, // Czech Republic
    { value: '+45', label: <><FlagIcon code="DK" /> +45</> }, // Denmark
    { value: '+253', label: <><FlagIcon code="DJ" /> +253</> }, // Djibouti
    { value: '+1-809', label: <><FlagIcon code="DO" /> +1-809</> }, // Dominican Republic
    { value: '+593', label: <><FlagIcon code="EC" /> +593</> }, // Ecuador
    { value: '+20', label: <><FlagIcon code="EG" /> +20</> }, // Egypt
    { value: '+503', label: <><FlagIcon code="SV" /> +503</> }, // El Salvador
    { value: '+372', label: <><FlagIcon code="EE" /> +372</> }, // Estonia
    { value: '+251', label: <><FlagIcon code="ET" /> +251</> }, // Ethiopia
    { value: '+679', label: <><FlagIcon code="FJ" /> +679</> }, // Fiji
    { value: '+358', label: <><FlagIcon code="FI" /> +358</> }, // Finland
    { value: '+33', label: <><FlagIcon code="FR" /> +33</> }, // France
    { value: '+995', label: <><FlagIcon code="GE" /> +995</> }, // Georgia
    { value: '+49', label: <><FlagIcon code="DE" /> +49</> }, // Germany
    { value: '+233', label: <><FlagIcon code="GH" /> +233</> }, // Ghana
    { value: '+30', label: <><FlagIcon code="GR" /> +30</> }, // Greece
    { value: '+502', label: <><FlagIcon code="GT" /> +502</> }, // Guatemala
    { value: '+504', label: <><FlagIcon code="HN" /> +504</> }, // Honduras
    { value: '+36', label: <><FlagIcon code="HU" /> +36</> }, // Hungary
    { value: '+354', label: <><FlagIcon code="IS" /> +354</> }, // Iceland
    { value: '+91', label: <><FlagIcon code="IN" /> +91</> }, // India
    { value: '+62', label: <><FlagIcon code="ID" /> +62</> }, // Indonesia
    { value: '+98', label: <><FlagIcon code="IR" /> +98</> }, // Iran
    { value: '+964', label: <><FlagIcon code="IQ" /> +964</> }, // Iraq
    { value: '+353', label: <><FlagIcon code="IE" /> +353</> }, // Ireland
    { value: '+972', label: <><FlagIcon code="IL" /> +972</> }, // Israel
    { value: '+39', label: <><FlagIcon code="IT" /> +39</> }, // Italy
    { value: '+1-876', label: <><FlagIcon code="JM" /> +1-876</> }, // Jamaica
    { value: '+81', label: <><FlagIcon code="JP" /> +81</> }, // Japan
    { value: '+962', label: <><FlagIcon code="JO" /> +962</> }, // Jordan
    { value: '+7', label: <><FlagIcon code="KZ" /> +7</> }, // Kazakhstan
    { value: '+254', label: <><FlagIcon code="KE" /> +254</> }, // Kenya
    { value: '+996', label: <><FlagIcon code="KG" /> +996</> }, // Kyrgyzstan
    { value: '+371', label: <><FlagIcon code="LV" /> +371</> }, // Latvia
    { value: '+961', label: <><FlagIcon code="LB" /> +961</> }, // Lebanon
    { value: '+370', label: <><FlagIcon code="LT" /> +370</> }, // Lithuania
    { value: '+352', label: <><FlagIcon code="LU" /> +352</> }, // Luxembourg
    { value: '+1-268', label: <><FlagIcon code="AG" /> +1-268</> }, // Antigua and Barbuda
    { value: '+236', label: <><FlagIcon code="CF" /> +236</> }, // Central African Republic
    { value: '+235', label: <><FlagIcon code="TD" /> +235</> }, // Chad
    { value: '+269', label: <><FlagIcon code="KM" /> +269</> }, // Comoros
    { value: '+242', label: <><FlagIcon code="CG" /> +242</> }, // Congo, Republic of the
    { value: '+243', label: <><FlagIcon code="CD" /> +243</> }, // Congo, Democratic Republic of the
    { value: '+240', label: <><FlagIcon code="GQ" /> +240</> }, // Equatorial Guinea
    { value: '+241', label: <><FlagIcon code="GA" /> +241</> }, // Gabon
    { value: '+1-473', label: <><FlagIcon code="GD" /> +1-473</> }, // Grenada
    { value: '+224', label: <><FlagIcon code="GN" /> +224</> }, // Guinea
    { value: '+245', label: <><FlagIcon code="GW" /> +245</> }, // Guinea-Bissau
    { value: '+225', label: <><FlagIcon code="CI" /> +225</> }, // Ivory Coast
    { value: '+965', label: <><FlagIcon code="KW" /> +965</> }, // Kuwait
    { value: '+218', label: <><FlagIcon code="LY" /> +218</> }, // Libya
    { value: '+265', label: <><FlagIcon code="MW" /> +265</> }, // Malawi
    { value: '+960', label: <><FlagIcon code="MV" /> +960</> }, // Maldives
    { value: '+223', label: <><FlagIcon code="ML" /> +223</> }, // Mali
    { value: '+692', label: <><FlagIcon code="MH" /> +692</> }, // Marshall Islands
    { value: '+222', label: <><FlagIcon code="MR" /> +222</> }, // Mauritania
    { value: '+230', label: <><FlagIcon code="MU" /> +230</> }, // Mauritius
    { value: '+377', label: <><FlagIcon code="MC" /> +377</> }, // Monaco
    { value: '+976', label: <><FlagIcon code="MN" /> +976</> }, // Mongolia
    { value: '+1-664', label: <><FlagIcon code="MS" /> +1-664</> }, // Montserrat
    { value: '+227', label: <><FlagIcon code="NE" /> +227</> }, // Niger
    { value: '+680', label: <><FlagIcon code="PW" /> +680</> }, // Palau
    { value: '+1-869', label: <><FlagIcon code="KN" /> +1-869</> }, // Saint Kitts and Nevis
    { value: '+1-758', label: <><FlagIcon code="LC" /> +1-758</> }, // Saint Lucia
    { value: '+1-784', label: <><FlagIcon code="VC" /> +1-784</> }, // Saint Vincent and the Grenadines
    { value: '+239', label: <><FlagIcon code="ST" /> +239</> }, // Sao Tome and Principe
    { value: '+248', label: <><FlagIcon code="SC" /> +248</> }, // Seychelles
    { value: '+232', label: <><FlagIcon code="SL" /> +232</> }, // Sierra Leone
    { value: '+677', label: <><FlagIcon code="SB" /> +677</> }, // Solomon Islands
    { value: '+252', label: <><FlagIcon code="SO" /> +252</> }, // Somalia
    { value: '+211', label: <><FlagIcon code="SS" /> +211</> }, // South Sudan
    { value: '+597', label: <><FlagIcon code="SR" /> +597</> }, // Suriname
    { value: '+670', label: <><FlagIcon code="TL" /> +670</> }, // Timor-Leste
    { value: '+228', label: <><FlagIcon code="TG" /> +228</> }, // Togo
    { value: '+676', label: <><FlagIcon code="TO" /> +676</> }, // Tonga
    { value: '+688', label: <><FlagIcon code="TV" /> +688</> }, // Tuvalu
    { value: '+678', label: <><FlagIcon code="VU" /> +678</> }, // Vanuatu
    { value: '+212', label: <><FlagIcon code="EH" /> +212</> }, // Western Sahara
    { value: '+967', label: <><FlagIcon code="YE" /> +967</> }, // Yemen
    { value: '+260', label: <><FlagIcon code="ZM" /> +260</> }, // Zambia
];


const customDropdownIndicator = ({ innerProps, innerRef }) => {
  return (
    <div ref={innerRef} {...innerProps} style={{ padding: '8px 8px 8px 0px', marginRight: '10px' }}>
      <svg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          d='M1 1.5L4 4.5L7 1.5'
          stroke='#29263A'
          strokeWidth='1.7'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>
    </div>
  );
};

const selectCustomStyle = {
  container: (provided) => ({
    ...provided,
    position: 'absolute',
    fontFamily: 'Lato, sans-serif',
    top: '1px',
    left: '1px',
    maxWidth: '120px',
    width: '120px'
  }),
  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? 'var(--main-green)' : 'white',
    cursor: 'pointer'
  }),
  control: (provided) => ({
    ...provided,
    minHeight: '50px',
    border: 'none',
    boxShadow: 'none'
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    order: '1',
    alignSelf: 'center',
    color: '#E8E8E8',
    height: '20px'
  })
};

export const GlobalInputSelect = ({ name, value, placeholder, onChange, type='text' }) => {

    const [countryCode, setCountryCode] = useState(phonePrefixOptions[0].value); // Default country code
    const [whatsAppNumber, setWhatsAppNumber] = useState(value);

    const handleCountryCodeChange = (selectedOption) => {
        setCountryCode(selectedOption.value);
        updateCombinedValue(selectedOption.value, whatsAppNumber);
    };

    const handleWhatsAppNumberChange = (e) => {
        setWhatsAppNumber(e.target.value);
        updateCombinedValue(countryCode, e.target.value);
    };

    const updateCombinedValue = (code, number) => {
        const combinedValue = `${code}${number}`;
        onChange(combinedValue); // Send the combined value back to the parent component
    };

  return (
    <Container>
        <Select
            options={phonePrefixOptions}
            defaultValue={phonePrefixOptions[0]}
            onChange={handleCountryCodeChange}
            styles={selectCustomStyle}
            components={{ DropdownIndicator: customDropdownIndicator }}
        />
        <GlobalInput
            name={name}
            type={type}
            value={whatsAppNumber}
            placeholder={placeholder}
            onChange={handleWhatsAppNumberChange}
        />
    </Container>
  );
};
