import React from 'react'
import { ColorRing } from 'react-loader-spinner'

const ColourRingLoader = () => {
    return (
        <>
            <ColorRing
                visible={true}
                height="80"
                width="80"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={['#388638', '#f76d21', '#FED101', '#DD0000', '#000000']}
            />
                    
        </>
    )
}

export default ColourRingLoader