
import {
    Success,
    Error,
    MobileText
} from './alert-styled';
import { Link } from 'react-router-dom';
import { GlobalCheckbox } from '../../../../components/global-checkbox/global-checkbox';
import {
    customDropdownIndicatorSmall,
    GlobalSelect,
    selectCustomStyleSmall
} from '../../../../components/global-select/global-select';

const options = [
    { label: '7 days', value: '7' },
    { label: '2 days', value: '7' },
    { label: '5 days', value: '7' }
];

export const Alert = (props) => {

    const { children, type } = props; 

    switch (type) {
        case 'error':
        return (
            <Error>
                {children}
            </Error>
        );
        case 'success':
        return (
            <Success>
                {children}
            </Success>
        );
        default:
        return null;
    }
           
};
