import { ROUTES } from './routes';

export const getRouteById = (routeId, params = {}) => {
    const route = Object.values(ROUTES).find(route => route.id === routeId);
    if (!route) {
        throw new Error(`Route with id ${routeId} not found`);
    }
  
    let path = route.path;
    Object.keys(params).forEach(param => {
        path = path.replace(`:${param}`, params[param]);
    });
  
    return path;
};