import styled from 'styled-components';
import { LatoFont } from '../../../../assets/style-helpers/style-helpers';
import { device } from '../../../../assets/style-helpers/breakpoints';

export const ErrorAlertsList = styled.div``;

export const ErrorAlert = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  background: #f9f9f9;
  border: 1px solid #dcdcdc;
  border-radius: 6px;

  @media ${device.tablet} {
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  & + & {
    margin-top: 16px;
  }

  > div {
    display: flex;
    align-items: center;
  }
`;

export const ErrorAlertText = styled.div`
  ${LatoFont};

  a {
    color: var(--main-green);
  }

  @media ${device.tablet} {
    display: none;
  }
`;

const Label = styled.div`
  margin: 0 16px;
  font-size: 12px;
  line-height: 1;
  padding: 9px 18px;
  border-radius: 4px;
`;

export const Success = styled(Label)`
  background: rgba(56, 134, 56, 0.15);
  color: var(--main-green);
`;

export const Error = styled(Label)`
  background: rgba(203, 59, 47, 0.15);
  color: #cb3b2f;
`;

export const Warning = styled(Label)`
  color: #db870a;
  background: rgba(244, 153, 16, 0.15);
`;

export const Info = styled(Label)`
  color: #767b99;
  background: rgba(118, 123, 153, 0.15);
`;

export const MobileText = styled.span`
  ${LatoFont};
  display: none;
  a {
    color: var(--main-green);
  }
  @media ${device.tablet} {
    display: block !important;
    flex-basis: 100%;
    margin-top: 8px;
  }
`;
