import { GlobalInput } from '../global-input/global-input';
import { EyeButton } from '../eye-button/eye-button';
import { useState } from 'react';
import { Container } from './global-input-password-styled';

export const GlobalInputPassword = ({ value, onChange, name, eyeButton='true' }) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  return (
    <Container>
      <GlobalInput
        name={name}
        placeholder={'Your password'}
        type={isPasswordVisible ? 'text' : 'password'}
        value={value}
        onChange={onChange}
      />

      { eyeButton === 'true' ?
        <EyeButton
          isVisible={isPasswordVisible}
          onClick={() => {
            setIsPasswordVisible(!isPasswordVisible);
          }}
        />
        : null
     }
    </Container>
  );
};
